<template>
  <div class="w-100 my-5">
    <div class="box-white d-flex flex-column">
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">{{ $t('title') }}</span>
        <input class="col-sm m-1 font-12" v-model="title" />
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">{{ $t('message') }}</span>
        <textarea class="col-sm m-1 font-12" v-model="message" />
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="sendMessageTask" :disabled="(!title || !message)">
          <span>{{ $t('send') }}</span>
        </v-btn>
      </div>
    </div>
    <div class="my-5">
      <div class="mt-5" v-for="(item, index) in notificationList" :key="(item, index)">
        <div class="m-1 p-0 box-white d-flex flex-column">
          <div class="mt-5 mx-5 d-flex justify-content-between">
            <span class="font-12 font-bold">{{ item.senderName }}</span>
            <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
          </div>
          <div class="mt-2 mb-5 mx-5 d-flex justify-content-between">
            <span class="mt-2 font-12">{{ item.body }}</span>
            <v-btn class="ml-auto" icon small @click="deleteNotificationTask(item.notificationId)">
              <i class="flaticon-delete color-red"></i>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, showLoading } from '../../../functions';

export default {
  name: 'SendMessage',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    notificationList() {
      return this.$store.state.notificationList;
    }
  },
  data() {
    return {
      title: '',
      message: ''
    }
  },
  methods: {
    getDateStringFromTimestamp,
    sendMessageTask() {
      if (!this.title) {
        this.$toast.info('Please enter title.');
        return;
      }
      if (!this.message) {
        this.$toast.info('Please enter message.');
        return;
      }
      if (!confirm(this.$t('confirm_send_message'))) {
        return;
      }
      const params = {
        functionName: 'sendMessage',
        title: this.title,
        message: this.message
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
        this.title = '';
        this.message = '';
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    deleteNotificationTask(notificationId) {
      if (!notificationId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm(this.$t('confirm_delete_message'))) {
        return;
      }
      const params = {
        functionName: 'deleteNotificationInfo',
        notificationId: notificationId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>